import React from "react";
import Car2 from "../Assets/car2.png";
import Car1 from "../Assets/car1.png";
import Car3 from "../Assets/car3.png";
import Car4 from "../Assets/car4.png";
import Car5 from "../Assets/car5.png";
import Car6 from "../Assets/car6.png";
import Car7 from "../Assets/car7.png";
import Car8 from "../Assets/car8.png";
import { Element } from "react-scroll";

const Work = () => {
  const workInfoData = [
    {
      image: Car1,
      title: "Kia Picanto 2014 г.",
      price: "Цена: 500 т.р.",
    },
    {
      image: Car2,
      title: "Daewoo Matiz 2004 г.",
      price: "Цена: 140 т.р.",
    },
    {
      image: Car3,
      title: "Volkswagen T3 1985 г.",
      price: "Цена: 210 т.р.",
    },
    {
      image: Car4,
      title: "Chevrolet lanos 2008 г.",
      price: "Цена: 200 т.р.",
    },
    {
      image: Car5,
      title: "Renault Logan 2007 г.",
      price: "Цена: 75 т.р.",
    },
    {
      image: Car6,
      title: "Nissan Vanette 2002 г.",
      price: "Цена: 250 т.р.",
    },
    {
      image: Car7,
      title: "Mitsubishi Dion 2001 год",
      price: "Цена: 350 т.р.",
    },
    {
      image: Car8,
      title: "Lifan Smily 2008 год",
      price: "Цена: 50 т.р.",
    },
  ];
  return (
    <div className="work-section-wrapper">
      <Element name="service" className="element">
        <div className="work-section-top">
          <p className="primary-subheading">Примеры</p>
          <h1 className="primary-heading">Выкупленные авто:</h1>
        </div>
        <div className="work-section-bottom">
          {workInfoData.map((data) => (
            <div className="work-section-info" key={data.title}>
              <div className="info-boxes-img-container">
                <img
                  src={data.image}
                  alt="airbagrostov подушки безопасности ростов"
                />
              </div>
              <h2>{data.title}</h2>
              <h2>{data.price}</h2>
            </div>
          ))}
        </div>
      </Element>
    </div>
  );
};

export default Work;
