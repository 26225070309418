import React from "react";
import Time from "../Assets/time.png";
import Car from "../Assets/car.png";
import Money from "../Assets/money.png";
import User from "../Assets/user.png";

import { Element } from "react-scroll";

const HomeWork = () => {
  const workInfoData = [
    {
      image: Time,
      title: "Быстрая оценка и встреча",
      text: 'Удаленно оценим авто по фото за 10 минут',
    },
    {
        image: Car,
        title: "Авто в любом состоянии",
        text: 'Выкуп всех типов транспортных средст',
      },
      {
        image: Money,
        title: "Предложим хорошую цену",
        text: 'Деньги сразу и удобным для вас способом',
      },
      {
        image: User,
        title: "Индивидуальный подход",
        text: 'Работаем более 25 лет, сотни довольных клиентов',
      },
  ];
  return (
    <div className="home-work-section-wrapper">
      <Element name="service" className="element">
        <div className="work-section-top">
        </div>
        <div className="home-work-section-bottom">
          {workInfoData.map((data) => (
            <div className="home-work-section-info" key={data.title}>
              <div className="home-info-boxes-img-container">
                <img
                  src={data.image}
                  alt="airbagrostov подушки безопасности ростов"
                />
              </div>
              <h2 style={{padding: '20px', paddingBottom: '0px'}}>{data.title}</h2>
              <p style={{padding: '20px'}}>{data.text}</p>
            </div>
          ))}
        </div>
      </Element>
    </div>
  );
};

export default HomeWork;
