import React from "react";

import Background from "../Assets/test1.jpg";
import AvitoImage from "../Assets/avito-1.png";
import { Element } from "react-scroll";

const About = () => {
  return (
    <div className="about-section-container">
      <img className="about-background-after" src={Background} alt="" />
      <img className="about-background" src={Background} alt="" />
      <div className="about-section-image-container">
        <img className="about-section-image" src={AvitoImage} alt="" />
      </div>
      <div className="about-section-text-container">
        <Element name="about" className="element">
          <p className="primary-subheading" id="top">
            О нас
          </p>
          <h1 className="primary-heading-left">С нами приятно иметь дело</h1>
          <p className="primary-text">
            Более <span style={{ color: "rgb(11, 41, 229)" }}>25 лет</span> радуем наших клиентов отличной ценой.
            Выкупаем авто в любом состоянии, также рассматриваем варианты под разбор.
            Помимо выкупа предлагаем каталог б/у запчастей и кузовных деталей по отличным ценам.
          </p>
          <p className="primary-text">
           Оценка на авито - {" "}
            <span style={{ color: "rgb(11, 41, 229)" }}>4,9 из 5 звезд</span>{" "}
            Выполняем свою работу честно, все обговариваем, предлагаем адекватные цены. Нас рекомендуют друзьям!
          </p>
          <div className="about-buttons-container">
            <button
              className="secondary-button"
              onClick={() => {
                window.location.href =
                  "https://www.avito.ru/brands/i153141785?gdlkerfdnwq=101&page_from%5B0%5D=from_item_header&page_from%5B1%5D=from_item_card&iid%5B0%5D=3607462557&iid%5B1%5D=3607462557";
              }}
            >
              Перейти на профиль Avito
            </button>
          </div>
        </Element>
      </div>
    </div>
  );
};

export default About;
